import React from 'react';

class JavascriptComponent extends React.Component {
    componentDidMount() {
        document.querySelector('.home').classList.add('blurActive');
    }

    onClose = (e) => {
        if (e.target.tagName != 'A') {
            document.querySelector('.home').classList.remove('blurActive');
            this.props.history.push('/');
        }
    }

    render() {
        localStorage.setItem('state', 'angular');
        return (
            <div
                className="inner-wrapper"
                onClick={this.onClose}>
                <button className="btn btn-link">X</button>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>JavaScript projects</h1>
                                <a href="https://developer.badileanu.ro/js/calculator/" target="_blank">calculator</a>
                                <a href="https://developer.badileanu.ro/js/no-bootstrap/" target="_blank">no bootstrap</a>
                                <a href="https://developer.badileanu.ro/js/pig-game/" target="_blank">pig game</a>
                                <a href="https://developer.badileanu.ro/js/rock-paper-scissors/" target="_blank">rock paper scissors</a>
                                <a href="https://slotmachine.badileanu.ro/" target="_blank">slot machine</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default JavascriptComponent;