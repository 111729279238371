import React from 'react';

class AngularComponent extends React.Component {

    componentDidMount() {
        document.querySelector('.home').classList.add('blurActive');
    }

    onClose = (e) => {
        // e.preventDefault();
        if (e.target.tagName != 'A') {
            // console.log(e.target.tagName);
            document.querySelector('.home').classList.remove('blurActive');
            this.props.history.push('/');
        }
    }

    render() {
        localStorage.setItem('state', 'angular');
        return (
            <div
                className="inner-wrapper"
                onClick={this.onClose}>
                <button className="btn btn-link">X</button>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>Angular projects</h1>
                                <a href="https://badileanu.ro/" target="_blank">badileanu.ro</a>
                                <a href="https://developer.badileanu.ro/angular/declaration/" target="_blank">covid declaration</a>
                                <a href="https://developer.badileanu.ro/angular/wikisearch/" target="_blank">wikisearch</a>
                                <a href="https://developer.badileanu.ro/angular/oshop/" target="_blank">oshop with firebase</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default AngularComponent;