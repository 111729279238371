import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomeComponent from '../components/home-component';
import AngularComponent from '../components/angular-component';
import JavascriptComponent from '../components/javascript-component';
import ReactComponent from '../components/react-component';
import ContactComponent from '../components/contact-component';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <div className="wrapper">
                <HomeComponent />
            </div>
            <Switch>
                    {/* <Route path="/" component={HomeComponent} exact={true} /> */}
                    <Route path="/angular" component={AngularComponent} />
                    <Route path="/javascript" component={JavascriptComponent} />
                    <Route path="/react" component={ReactComponent} />
                    <Route path="/contact" component={ContactComponent} />
                </Switch>
        </BrowserRouter>
    );
};

export default AppRouter;