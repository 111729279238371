import React from 'react';

const AngularLogoComponent = () => {
    return (
<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <path fill="#f0f0f0" d="m15.924 10.361-2.472 5.863h5.327l-2.833-5.917-.023.053zm.033-8.476L2.405 6.654l2.141 17.754 11.425 6.279 11.482-6.365 2.14-17.752-13.637-4.685ZM21.7 22.97l-1.84-4.259h-7.508l-1.68 4.202-3.125.057 8.377-18.637 8.67 18.637h-2.895Z"/>
</svg>
    );
};

export default AngularLogoComponent;