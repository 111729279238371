import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import pkg from '../../package.json';

import ReactLogoComponent from '../components/logos/react-logo-component';
import AngularLogoComponent from '../components/logos/angular-logo-component';
import JavascriptLogoComponent from './logos/javascript-logo-component';

const HomeComponent = () => {
  console.log(`version: ${pkg.version}`);

  return (
    <div className="home">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-6 text-right">
            <h1>
              {/* <Link className="contact" to={{ pathname: '' }} target="_blank">
                Razvan Badileanu
              </Link> */}
              <span className="author">Razvan Badileanu</span>
              <span className="description">Web development</span>
            </h1>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-6 text-right mb-4">
            <h3 className="title mb-4">PROJECTS</h3>
            <p>Here you can find some of my personal work</p>
            <p>For details, please select a category</p>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-6 text-right">
            <NavLink to="/angular" activeClassName="active" className="icon">
              <AngularLogoComponent />
            </NavLink>
            <NavLink to="/javascript" activeClassName="active" className="icon">
              <JavascriptLogoComponent />
            </NavLink>
            <NavLink to="/react" activeClassName="active" className="icon">
              <ReactLogoComponent />
            </NavLink>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      {/* <NavLink to="/" activeClassName="active" exact={true}>Home</NavLink> */}
    </div>
  );
};

export default HomeComponent;
